import React from 'react';
import { Link, graphql } from 'gatsby';
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import Layout from '../components/layout';
import Seo from '../components/seo';


const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const { t } = useTranslation();
  const tip = t('tags-tip');

  const tagHeader = `${totalCount} post${totalCount === 1 ? '' : tip} "${tag}" ${t('tags-tagged')}`;
  return (
    <Layout>
      <Seo id="blogList tagList" title="Blog" keywords={[`blog`, `lista`]} />

        <section className='hero'>
          <div className='content'>
            <div className='container'>
              <h2><Trans>btn-seeMore</Trans></h2>
              <h1>{tagHeader}</h1> 
              <AnchorLink to="/tags#content" title="Zlecenia" className="btn btn-primary">
                <Trans>btn-seeAll</Trans>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g transform="translate(24) rotate(90)">
                    <path d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" fill="currentColor"/>
                  </g>
                </svg>
              </AnchorLink>
            </div>
          </div>
          <div className="lines">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className='overlay'></div>
          <StaticImage
              src="../images/pages/news.jpg"
              alt="News Hero"
              placeholder="blurred"
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
              quality={60}
          />
        </section>

        <div id="content">
          <div className='container'>
            {edges.map(({ node }) => {
              const { title, date } = node.frontmatter;
              const { slug } = node.fields;
              return (
                <article
                  key={slug}
                  className="postItem"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <Link to={slug} className="thumbnail">
                    <GatsbyImage image={node.frontmatter.thumbnail.childImageSharp.gatsbyImageData} alt={node.frontmatter.title} />
                  </Link>
                  <div className="postItem__content">
                    <Link to={slug}>
                      <h2>{title}</h2>
                    </Link>
                    <p>{node.excerpt}</p>
                    <div className="postItem__meta">
                      <time>{date}</time>
                    </div>
                  </div>
                </article>
              );
            })}
            <footer>
              <Link to="/tags" className='btn btn-primary'><Trans>tags-AllTags</Trans></Link>
            </footer>
          </div>
        </div>

       
          

    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY", locale: $language)
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 560
                  formats: [AUTO, WEBP, AVIF]
                  quality: 80
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`;
