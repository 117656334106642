import React from 'react';
import { graphql } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import {Trans} from 'gatsby-plugin-react-i18next';

// import { Disqus } from 'gatsby-plugin-disqus';

import Layout from '../components/layout';
import Seo from '../components/seo';

const PostTemplate = ({ data, location }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark; //id
  const siteTitle = frontmatter.title || `Title`
  const shareDesc = frontmatter.description
  const clearUrl = location.pathname.replace(/\/+$/, '');

  // const disqusUrl = `https://www.4synchronicity.com/` + location.pathname
  const shareData = {
    title: siteTitle,
    text: shareDesc,
    url: location.pathname
  }

  async function shareBtn() {
    try {
      await navigator.share(shareData)
    } catch(err) {
      
    }
  }

  // let disqusConfig = {
  //   url: disqusUrl,
  //   identifier: id,
  //   title: frontmatter.title,
  // }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description || ''}
        keywords={[`blog`, `post`, `wpis`, ...frontmatter.tags]}
        id="blogPost"
      />
      <article itemScope itemType="http://schema.org/Article">
        <section className='hero'>
          <div className='content'>
            <div className='container'>
              <p>{frontmatter.tags}</p>
              <h1>{frontmatter.title}</h1> 
              <AnchorLink to={clearUrl + "#content"} title="Services" className="btn btn-primary">
                <Trans>btn-seeMore</Trans>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g transform="translate(24) rotate(90)">
                    <path d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" fill="currentColor"/>
                  </g>
                </svg>
              </AnchorLink>
            </div>
          </div>
          <div className="lines">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className='overlay'></div>
          <GatsbyImage image={frontmatter.cover.childImageSharp.gatsbyImageData} alt={frontmatter.title} />
        </section>

        <section id="content">
          <div className='container'>
            <header>
                {/* <h2 itemProp="headline">{frontmatter.title}</h2> */}
                <div className='caption'>
                  <div className='bio'>
                    <StaticImage
                      className="bio-avatar"
                      layout="fixed"
                      formats={["auto", "webp", "avif"]}
                      src="../images/avatar-88x88.jpg"
                      width={60}
                      height={60}
                      quality={95}
                      alt="Profile picture"
                    />
                    <span>
                      <span>Mariusz Szałaj</span>
                      <time>{frontmatter.date}</time>
                    </span>
                  </div>
                  <div className='share'>
                    <button id='shareButton' onClick={shareBtn}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
                        <g id="share-2" transform="translate(-2 -1)">
                          <circle id="Ellipse_62" data-name="Ellipse 62" cx="3" cy="3" r="3" transform="translate(15 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                          <circle id="Ellipse_63" data-name="Ellipse 63" cx="3" cy="3" r="3" transform="translate(3 9)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                          <circle id="Ellipse_64" data-name="Ellipse 64" cx="3" cy="3" r="3" transform="translate(15 16)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                          <line id="Line_222" data-name="Line 222" x2="6.83" y2="3.98" transform="translate(8.59 13.51)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                          <line id="Line_223" data-name="Line 223" x1="6.82" y2="3.98" transform="translate(8.59 6.51)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </header>
              <div itemProp="articleBody" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </section>

    
      </article>

      {/* <Disqus config={disqusConfig} /> */}


    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      # id
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY", locale: $language)
        title
        description
        tags
        cover {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              quality: 60
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;
