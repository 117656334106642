import * as React from "react"
import { Trans } from 'gatsby-plugin-react-i18next';

import LogoAuchan from "../images/brands/auchan.svg";
import LogoBosch from "../images/brands/bosch.svg";
import LogoDamen from "../images/brands/damen.svg";
import LogoDrmax from "../images/brands/drmax.svg";
import LogoHager from "../images/brands/hager.svg";
import LogoSkanska from "../images/brands/skanska.svg";

const sectionBrands = () => (

<section className="brands">
    <div className="lines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="container">
        <div className="copy">
            <h3><Trans>brands-tagline</Trans></h3>
            <h2><Trans>brands-header</Trans></h2>
        </div>
        <div className="brandLogo">
            <div>
                <LogoAuchan />
            </div>
            <div>
                <LogoBosch />
            </div>
            <div>
                <LogoDamen />
            </div>
            <div>
                <LogoDrmax />
            </div>
            <div>
                <LogoHager />
            </div>
            <div>
                <LogoSkanska />
            </div>
        </div>
    </div>
</section>

);

export default sectionBrands;