import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  // const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/avatar-88x88.jpg"
        width={88}
        height={88}
        quality={95}
        alt="Profile picture"
      />
      <div className="author">
        <p className="lead">{author.name}</p>
        <p>+48 731 531 703</p>
        <a href="mailto:mariusz.szalaj@4synchronicity.com">mariusz.szalaj@4synchronicity.com</a>
      </div>
      {/* 
      {author?.name && (
        <p>
          {author.name} {author?.summary || null}
          {` `}
          <a href={`https://twitter.com/${social?.twitter || ``}`}>Twitter</a>
        </p>
      )} 
      */}
    </div>
  )
}

export default Bio
