import React from 'react';
import { graphql } from "gatsby"
import Layout from '../components/layout';
import Seo from '../components/seo';

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image"
import {Trans} from 'gatsby-plugin-react-i18next';

import ServicesAside from "../components/aside"
import Bio from '../components/bio';

import MapOne from "../images/maps/map_1_1.svg";
import MapTwo from "../images/maps/map_1_2.svg";
import MapThreeFour from "../images/maps/map_1_3_4.svg";

const ComprehensiveGovernance = () => (
  <Layout>
    <Seo title="Business Process Simplification" id="services" keywords={[`usługi`, `migracja procesów`]} />

      <section className='hero'>
        <div className='content'>
          <div className='container'>
            <p><Trans>businessProcess-tagline</Trans></p>
            <h1><Trans>businessProcess-header</Trans></h1> 
            <AnchorLink to="/services#content" title="Services" className="btn btn-primary">
              <Trans>btn-seeMore</Trans>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(24) rotate(90)">
                  <path d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" fill="currentColor"/>
                </g>
              </svg>
            </AnchorLink>
          </div>
        </div>
        <div className="lines">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='overlay'></div>
        <StaticImage
            src="../images/pages/services.jpg"
            alt="News Hero"
            placeholder="blurred"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            quality={60}
        />
      </section>

      <div id="content">
        <div className='container'>
            <ServicesAside/>
            <div>
                <header>
                    <h2><Trans>businessProcess-h2-1</Trans></h2>
                    <p><Trans>businessProcess-p-1</Trans></p>
                </header>
                
                <section className='activitySector'>
                  <h3><Trans>businessProcess-h3-1</Trans></h3>
                  <p><span><Trans>services-Complex</Trans></span> <Trans>businessProcess-p-2</Trans></p>
                  <p><span><Trans>services-Results</Trans></span> <Trans>businessProcess-p-3</Trans></p>
                  <div className='activityRange'>
                    <h4><Trans>businessProcess-h4-1</Trans></h4>
                    <p><span><Trans>services-Eu-Africa</Trans></span> <Trans>businessProcess-country-1</Trans></p>
                    <p><span><Trans>services-North-CentralAmerica</Trans></span> <Trans>services-North-CentralAmerica-pack-1</Trans></p>
                    <p><span><Trans>services-Asia-Pacific</Trans></span> <Trans>services-Philippines</Trans></p>
                  </div>
                  <picture>
                    <MapOne/>
                  </picture>
                </section>

                <section className='activitySector'>
                  <h3><Trans>businessProcess-h3-2</Trans></h3>
                  <p><span><Trans>services-Complex</Trans></span> <Trans>businessProcess-p-4</Trans></p>
                  <p><span><Trans>services-Results</Trans></span> <Trans>businessProcess-p-5</Trans></p>
                  <div className='activityRange'>
                    <h4><Trans>services-Details</Trans></h4>
                    <p><span><Trans>services-Eu-Africa</Trans></span> <Trans>businessProcess-country-2</Trans></p>
                    <p><span><Trans>services-North-CentralAmerica</Trans></span> <Trans>services-North-CentralAmerica-pack-1</Trans></p>
                    <p><span><Trans>services-South-America</Trans></span> <Trans>businessProcess-country-3</Trans></p>
                    <p><span><Trans>services-Asia-Pacific</Trans></span> <Trans>businessProcess-country-4</Trans></p>
                  </div>
                  <picture>
                    <MapTwo/>
                  </picture>
                </section>

                <section className='activitySector'>
                  <h3><Trans>businessProcess-h3-3</Trans></h3>
                  <p><span><Trans>services-Complex</Trans></span> <Trans>businessProcess-p-6</Trans></p>
                  <p><span><Trans>services-Results</Trans></span> <Trans>businessProcess-p-7</Trans></p>
                  <div className='activityRange'>
                    <h4><Trans>services-Details</Trans></h4>
                    <p><span><Trans>services-Eu-Africa</Trans></span> <Trans>businessProcess-country-5</Trans></p>
                    <p><span><Trans>services-North-CentralAmerica</Trans></span> <Trans>businessProcess-country-6</Trans></p>
                    <p><span><Trans>services-Asia-Pacific</Trans></span> <Trans>businessProcess-country-4</Trans></p>
                  </div>
                  <picture>
                    <MapThreeFour/>
                  </picture>
                </section>

                <section className='activitySector'>
                  <h3><Trans>businessProcess-h3-3</Trans></h3>
                  <p><span><Trans>services-Complex</Trans></span> <Trans>businessProcess-p-8</Trans></p>
                  <p><span><Trans>services-Results</Trans></span> <Trans>businessProcess-p-9</Trans></p>
                  <div className='activityRange'>
                    <h4><Trans>services-Details</Trans></h4>
                    <p><span><Trans>services-Eu-Africa</Trans></span> <Trans>businessProcess-country-7</Trans></p>
                    <p><span><Trans>services-North-CentralAmerica</Trans></span> <Trans>businessProcess-country-8</Trans></p>
                  </div>
                  <picture>
                    <MapThreeFour/>
                  </picture>
                </section>

                <section className='contact'>
                  <h4><Trans>services-Contact</Trans></h4>
                  <Bio/>
                </section>

            </div>
        </div>
      </div>
  </Layout>
);

export default ComprehensiveGovernance;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`