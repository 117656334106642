import React from 'react';
import { graphql } from "gatsby"
import Layout from '../components/layout';
import Seo from '../components/seo';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image"
import {Trans} from 'gatsby-plugin-react-i18next';

const KontaktPage = () => (
  <Layout>
    <Seo id="contactPage" title="Contact" keywords={[`kontakt`, `strona kontaktowa`, `formularz kontaktowy`]} />

    <section className='hero'>
      <div className='content'>
        <div className='container'>
          <h2><Trans>contact-tagline</Trans></h2>
          <h1><Trans>contact-header</Trans></h1> 
          <AnchorLink to="/contact#content" title="Zlecenia" className="btn btn-primary">
            Zobacz więcej
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g transform="translate(24) rotate(90)">
                <path d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" fill="currentColor"/>
              </g>
            </svg>
          </AnchorLink>
        </div>
      </div>
      <div className="lines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='overlay'></div>
      <StaticImage
          src="../images/pages/contact.jpg"
          alt="GrumpyDev Contact"
          placeholder="blurred"
          layout="fullWidth"
          formats={["auto", "webp", "avif"]}
          quality={60}
      />
    </section>

    <div id="content">
      
      <div className="lines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <section className='container'>
        <header>
          <h3><Trans>contact-h3</Trans></h3>
          <p><Trans>contact-p</Trans></p>
        </header>
        <div className=''>
          <StaticImage
              src="../images/mariusz_szalaj.jpg"
              alt="GrumpyDev Contact"
              placeholder="blurred"
              layout="constrained"
              formats={["auto", "webp", "avif"]}
              quality={100}
          />
          <h2>Mariusz Szałaj</h2>
          <h3>Co Funder</h3>
          <p>e-mail: <a href="mailto:mariusz.szalaj@4synchronicity.com">mariusz.szalaj@4synchronicity.com</a></p>
          <p>telefon: <a href="tel:789205000">789 205 000</a></p>
        </div>
        <div className=''>
          <StaticImage
              src="../images/marta_lizurej.jpg"
              alt="GrumpyDev Contact"
              placeholder="blurred"
              layout="constrained"
              formats={["auto", "webp", "avif"]}
              quality={100}
          />
          <h2>Marta Lizurej</h2>
          <h3>SSC &amp; Business Transformation Advisory</h3>
          <p>e-mail: <a href="mailto:marta.lizurej@4synchronicity.com">marta.lizurej@4synchronicity.com</a></p>
          <p>telefon: <a href="tel:731531704">731 531 704</a></p>
        </div>
        <div className=''>
          <StaticImage
              src="../images/anna_nizio.jpg"
              alt="GrumpyDev Contact"
              placeholder="blurred"
              layout="constrained"
              formats={["auto", "webp", "avif"]}
              quality={100}
          />
          <h2>Anna Nizio</h2>
          <h3>SSC &amp; Business Transformation Advisory</h3>
          <p>e-mail: <a href="mailto:anna.nizio@4synchronicity.com">anna.nizio@4synchronicity.com</a></p>
          <p>telefon: <a href="tel:731531702">731 531 702</a></p>
        </div>
        <div className=''>
          <StaticImage
              src="../images/joanna_manikowska.jpg"
              alt="GrumpyDev Contact"
              placeholder="blurred"
              layout="constrained"
              formats={["auto", "webp", "avif"]}
              quality={100}
          />
          <h2>Joanna Manikowska</h2>
          <h3>Business Relations &amp; Development</h3>
          <p>e-mail: <a href="mailto:joanna.manikowska@4synchronicity.com">joanna.manikowska@4synchronicity.com</a></p>
          <p>telefon: <a href="tel:600400878">600 400 878</a></p>
        </div>
      </section>
    </div>
  </Layout>
);
export default KontaktPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`