import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from 'gatsby-plugin-react-i18next';

const sectionTestimonials = () => (

<section className="testimonials">
    <div className="container">
        <h4><Trans>testimonials-header</Trans></h4>
        <div className="author">
            <StaticImage
                className="avatar"
                layout="fixed"
                formats={["auto", "webp", "avif"]}
                src="../images/avatar-88x88.jpg"
                width={88}
                height={88}
                quality={95}
                alt="Profile picture"
            />
            <div>
                <p className="name">Mariusz Szałaj</p>
                <p className="position">Co Founder</p>
            </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.609 93.774">
            <path fill="#eff0f8" d="M21.764.004C8.903 4.419-.13 14.23-.291 27.066c0 11.86 7.139 18.093 14.443 18.166 3.32.034 6.675-1.206 9.409-3.767 4.052-4.214 4.544-10.467.685-14.444-2.511-2.511-7.407-4.31-12.348-1.984-1.555.697-2.763.747-3.498-.16-.644-.796-.638-1.459-.638-2.879.102-7.928 7.977-15.227 14.002-18.686V.005zm34 0C42.903 4.419 33.87 14.23 33.709 27.066c0 11.86 7.139 18.093 14.443 18.166 3.32.034 6.675-1.206 9.409-3.767 4.052-4.214 4.544-10.467.685-14.444-2.511-2.511-7.407-4.31-12.348-1.984-1.555.697-2.763.747-3.498-.16-.644-.796-.638-1.459-.638-2.879.102-7.928 7.977-15.227 14.002-18.686V.005zM86.166 48.48c-3.32-.033-6.674 1.207-9.408 3.768-4.052 4.214-4.545 10.466-.686 14.443 2.512 2.512 7.408 4.312 12.348 1.985 1.555-.697 2.764-.748 3.498.16.644.796.639 1.459.639 2.879-.102 7.928-7.978 15.227-14.002 18.685v3.309c12.86-4.415 21.893-14.227 22.054-27.063 0-11.86-7.138-18.092-14.443-18.166zm34 0c-3.32-.033-6.674 1.207-9.408 3.768-4.052 4.214-4.545 10.466-.686 14.443 2.512 2.512 7.408 4.312 12.348 1.985 1.555-.697 2.764-.748 3.498.16.644.796.639 1.459.639 2.879-.102 7.928-7.978 15.227-14.002 18.685v3.309c12.86-4.415 21.893-14.227 22.054-27.063 0-11.86-7.138-18.092-14.443-18.166z"/>
        </svg>
    </div>
    <div className="lines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</section>

);

export default sectionTestimonials;