import { kebabCase } from 'lodash';
import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {Trans} from 'gatsby-plugin-react-i18next';

import Swiper from 'react-id-swiper';

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import SectionAboutUs from "../components/section-aboutus"
import SectionServices from "../components/section-services"
import SectionBrands from "../components/section-brands"
import SectionTestimonials from "../components/section-testimonials"

const sliderParams = {
  slidesPerView: 1,
}

const Mainpage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  // const { t } = useTranslation();
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Start" />
        <Bio />
        <p>
          No blog posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Start" id="mainpage" />

      <section className='heroBlog'>

        <Swiper {...sliderParams}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug
            return (
              <div key={post.fields.slug}>
                  <div className='overlay'></div>
                  <div className="lines">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <GatsbyImage image={post.frontmatter.cover.childImageSharp.gatsbyImageData} alt={post.frontmatter.title} />
                  <div className='slide-post'>
                    <div className='container'>
                      {post.frontmatter.tags ? (
                        <span className="tags">
                          {post.frontmatter.tags.map(tag => (
                            <Link key={tag + `tag`} to={`/tags/${kebabCase(tag)}/`} className="label">{tag}</Link>
                          ))}
                        </span>
                      ) : null}
                      <h1>
                        <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h1>
                      <Link to={post.fields.slug} title={title} className="btn btn-primary">
                        <Trans>btn-seeMore</Trans>
                        {/* {t('See more')} */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" fill="currentColor"/>
                        </svg>
                      </Link>
                    </div>
                  </div>
              </div>
            )
          })}
        </Swiper>

        <div className='heroPostList'>
          <div className="container" >
            {posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug
              return (
                <div className="heroPostItem" key={post.fields.slug}>
                  <article
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    {post.frontmatter.tags ? (
                      <span className="tags">
                        {post.frontmatter.tags.map(tag => (
                          <Link key={tag + `tag`} to={`/tags/${kebabCase(tag)}/`} className="label">{tag}</Link>
                        ))}
                      </span>
                    ) : null}
                    <h2>
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                  </article>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <SectionAboutUs />
      <SectionServices />
      <SectionBrands />
      <SectionTestimonials />
    </Layout>
  )
}

export default Mainpage

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    locales: allLocale(
      filter: { language: {eq: $language} }
      ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }, 
      limit: 4
      filter: { frontmatter: {lang: { eq: $language}} }
    ) {
      nodes {
        excerpt(pruneLength: 110)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY", locale: "pl")
          title
          description
          tags
          cover {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                quality: 60
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`
