import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {Trans} from 'gatsby-plugin-react-i18next';

const servicesAside = () => {
  return (
  <aside>
      <nav>
          <AnchorLink to="/business-process-simplification#content" title="Optymalizacja procesów biznesowych"><Trans>aside-a-1</Trans></AnchorLink>
          <AnchorLink to="/comprehensive-governance#content" title="Transformacja modelu biznesowego"><Trans>aside-a-2</Trans></AnchorLink>
          <AnchorLink to="/shared-services#content" title="Centra usług wspólnych"><Trans>aside-a-3</Trans></AnchorLink>
          <AnchorLink to="/process-migration#content" title="Migracja procesów"><Trans>aside-a-4</Trans></AnchorLink>
          <AnchorLink to="/technology-deployment#content" title="Wdrażanie technologii"><Trans>aside-a-5</Trans></AnchorLink>
          <AnchorLink to="/decision-support#content" title="Wsparcie decyzji biznesowych"><Trans>aside-a-6</Trans></AnchorLink>
          <AnchorLink to="/project-management#content" title="Zarządzanie projektami"><Trans>aside-a-7</Trans></AnchorLink>
          <AnchorLink to="/investment-incentives#content" title="Wsparcie w uzyskaniu zachęt inwestycyjnych"><Trans>aside-a-8</Trans></AnchorLink>
      </nav>
  </aside>
  )
}
 
export default servicesAside
