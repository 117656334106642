import React from 'react';
import { graphql } from "gatsby"
import Layout from '../components/layout';
import Seo from '../components/seo';

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image"
import {Trans} from 'gatsby-plugin-react-i18next';

import ServicesAside from "../components/aside"

const ServicesPage = () => (
  <Layout>
    <Seo title="Services" id="services" keywords={[`usługi`, `migracja procesów`]} />

      <section className='hero'>
        <div className='content'>
          <div className='container'>
            <p><Trans>servicesPage-tagline</Trans></p>
            <h1><Trans>servicesPage-header</Trans></h1> 
            <AnchorLink to="/services#content" title="Services" className="btn btn-primary">
              <Trans>btn-seeMore</Trans>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(24) rotate(90)">
                  <path d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" fill="currentColor"/>
                </g>
              </svg>
            </AnchorLink>
          </div>
        </div>
        <div className="lines">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='overlay'></div>
        <StaticImage
            src="../images/pages/services.jpg"
            alt="News Hero"
            placeholder="blurred"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            quality={60}
        />
      </section>

      <div id="content">
        <div className='container'>
          <ServicesAside/>
          <div>
            <header>
              <h2><Trans>servicesPage-h2-1-1</Trans></h2>
              <p><Trans>servicesPage-p-1</Trans></p>
              <p><Trans>servicesPage-p-2</Trans></p>
            </header>
            <ul>
              <li><Trans>servicesPage-li-1-1</Trans></li>
              <li><Trans>servicesPage-li-1-2</Trans></li>
              <li><Trans>servicesPage-li-1-3</Trans></li>
              <li><Trans>servicesPage-li-1-4</Trans></li>
              <li><Trans>servicesPage-li-1-5</Trans></li>
              <li><Trans>servicesPage-li-1-6</Trans></li>
              <li><Trans>servicesPage-li-1-7</Trans></li>
              <li><Trans>servicesPage-li-1-8</Trans></li>
              <li><Trans>servicesPage-li-1-9</Trans></li>
              <li><Trans>servicesPage-li-1-10</Trans></li>
            </ul>
            <p className='lead'><Trans>servicesPage-p-3</Trans></p>
            <ul>
              <li><Trans>servicesPage-li-2-1</Trans></li>
              <li><Trans>servicesPage-li-2-2</Trans></li>
              <li><Trans>servicesPage-li-2-3</Trans></li>
              <li><Trans>servicesPage-li-2-4</Trans></li>
              <li><Trans>servicesPage-li-2-5</Trans></li>
            </ul>
            <p className='lead'><Trans>servicesPage-p-4</Trans></p>
          </div>
        </div>
      </div>

  </Layout>
);

export default ServicesPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`