import React from 'react';
import { Link, graphql } from 'gatsby';

import { kebabCase } from 'lodash';
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from '../components/layout';
import Seo from '../components/seo';

const TagsPage = ({ data }) => {
  const allTags = data.allMarkdownRemark.group;

  return (
    <Layout>
      <Seo id="allTags blogList" title="Tagi" keywords={[`tagi`]} />

      <section className='hero'>
        <div className='content'>
          <div className='container'>
            <h2>Zobacz wszystkie</h2>
            <h1>Tagi</h1> 
            <AnchorLink to="/tags#content" title="Zlecenia" className="btn btn-primary">
              Zobacz więcej
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(24) rotate(90)">
                  <path d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" fill="currentColor"/>
                </g>
              </svg>
            </AnchorLink>
          </div>
        </div>
        <div className="lines">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='overlay'></div>
        <StaticImage
            src="../images/pages/news.jpg"
            alt="News Hero"
            placeholder="blurred"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            quality={60}
        />
      </section>

      <div id="content">
        <div className='container'>
          <h2>Wszystkie tagi</h2>
          <div className='tags'>
            {allTags.map(tag => (
              <label key={tag.fieldValue}>
                <Link className="label" to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </label>
            ))}
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
