exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-business-process-simplification-js": () => import("./../../../src/pages/business-process-simplification.js" /* webpackChunkName: "component---src-pages-business-process-simplification-js" */),
  "component---src-pages-comprehensive-governance-js": () => import("./../../../src/pages/comprehensive-governance.js" /* webpackChunkName: "component---src-pages-comprehensive-governance-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-decision-support-js": () => import("./../../../src/pages/decision-support.js" /* webpackChunkName: "component---src-pages-decision-support-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-incentives-js": () => import("./../../../src/pages/investment-incentives.js" /* webpackChunkName: "component---src-pages-investment-incentives-js" */),
  "component---src-pages-process-migration-js": () => import("./../../../src/pages/process-migration.js" /* webpackChunkName: "component---src-pages-process-migration-js" */),
  "component---src-pages-project-management-js": () => import("./../../../src/pages/project-management.js" /* webpackChunkName: "component---src-pages-project-management-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shared-services-js": () => import("./../../../src/pages/shared-services.js" /* webpackChunkName: "component---src-pages-shared-services-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-technology-deployment-js": () => import("./../../../src/pages/technology-deployment.js" /* webpackChunkName: "component---src-pages-technology-deployment-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blogList.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-list-js": () => import("./../../../src/templates/tagList.js" /* webpackChunkName: "component---src-templates-tag-list-js" */)
}

