// import { kebabCase } from 'lodash';
import React from 'react';
import { graphql, Link } from 'gatsby';
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {Trans} from 'gatsby-plugin-react-i18next';

import Layout from '../components/layout';
import Seo from '../components/seo';

const BlogPage = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const { currentPage, numPages } = pageContext;
  const pathPrefix = 'news'; // blog
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? `${pathPrefix}/`
      : `${pathPrefix}/${(currentPage - 1).toString()}`;
  const nextPage = `${pathPrefix}/${(currentPage + 1).toString()}`;
  // const allTags = data.allMarkdownRemark.group;
  
  return (
    <Layout>
      <Seo id="blogList" title="Blog" keywords={[`blog`, `lista`]} />

      <section className='hero'>
        <div className='content'>
          <div className='container'>
            <h2><Trans>btn-ReadMore</Trans></h2>
            <h1><Trans>blogList-header</Trans></h1> 
            <AnchorLink to="/news#content" title="Zlecenia" className="btn btn-primary">
              <Trans>btn-seeMore</Trans>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(24) rotate(90)">
                  <path d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" fill="currentColor"/>
                </g>
              </svg>
            </AnchorLink>
          </div>
        </div>
        <div className="lines">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='overlay'></div>
        <StaticImage
            src="../images/pages/news.jpg"
            alt="News Hero"
            placeholder="blurred"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            quality={60}
        />
      </section>

      <div id="content">
        <div className="lines">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className='container'>
          {posts.map(post => (
            <article
              key={post.node.id}
              className="postItem"
              itemScope
              itemType="http://schema.org/Article"
            >
              <Link to={post.node.fields.slug} className="thumbnail">
                <GatsbyImage image={post.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData} alt={post.node.frontmatter.title} />
              </Link>
              <time>{post.node.frontmatter.date}</time>
              <Link className="btn btn-link" to={post.node.fields.slug}>
                <Trans>btn-More</Trans>
                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="2" viewBox="0 0 53 2">
                    <rect width="53" height="2"/>
                </svg>
              </Link>
              <div className="postItem__content">
                <Link to={post.node.fields.slug}>
                  <h2>{post.node.frontmatter.title}</h2>
                </Link>
                <p>{post.node.excerpt}</p>
                  {/* {post.node.frontmatter.tags ? (
                    <span className="tags">
                      {post.node.frontmatter.tags.map(tag => (
                        <Link key={tag + `tag`} to={`/tags/${kebabCase(tag)}/`} className="label">{tag}</Link>
                      ))}
                    </span>
                  ) : null} */}
              </div>
            </article>
          ))}

          <div className="pagination">
            {!isFirst && (
              <Link to={`/${prevPage}`} rel="prev">
                <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.829" viewBox="0 0 8.414 14.829">
                  <path id="chevron-left" d="M15,18,9,12l6-6" transform="translate(-8 -4.586)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                </svg>
              </Link>
            )}
            {Array.from({ length: numPages }, (_, i) => (
              <Link
                key={`pagination-number${i + 1}`}
                to={`/${pathPrefix}/${i === 0 ? '' : i + 1}`}
                >
                {i + 1}
              </Link>
            ))}
            {!isLast && (
              <Link to={`/${nextPage}`} rel="next">
                <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.829" viewBox="0 0 8.414 14.829">
                  <path id="chevron-right" d="M9,18l6-6L9,6" transform="translate(-7.586 -4.586)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                </svg>
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: {lang: { eq: $language}} }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY", locale: $language)
            title
            tags
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  formats: [AUTO, WEBP, AVIF]
                  quality: 80
                  placeholder: BLURRED
                )
              }
            }
            lang
          }
        }
      }
    }
  }
`;
