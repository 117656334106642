import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { graphql } from "gatsby"

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image"
import {Trans} from 'gatsby-plugin-react-i18next';

import SectionTestimonials from "../components/section-testimonials"

const AutorPage = () => (
  <Layout>
    <Seo title="About us" id="about" keywords={[`o nas`, `4synchronicity`]} />
    <section className='hero'>
      <div className='content'>
        <div className='container'>
          <h2><Trans>aboutus-tagline</Trans></h2>
          <h1><Trans>aboutus-header</Trans></h1> 
          <AnchorLink to="/services#content" title="Services" className="btn btn-primary">
            <Trans>btn-seeMore</Trans>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g transform="translate(24) rotate(90)">
                <path d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" fill="currentColor"/>
              </g>
            </svg>
          </AnchorLink>
        </div>
      </div>
      <div className="lines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='overlay'></div>
      <StaticImage
          src="../images/pages/aboutus.jpg"
          alt="News Hero"
          placeholder="blurred"
          layout="fullWidth"
          formats={["auto", "webp", "avif"]}
          quality={60}
      />
    </section>

    <div id="content" className='aboutus'>
      <div className="lines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='container'>
        <header>
          <h2><Trans>aboutus-h2</Trans></h2>
          <p><Trans>aboutus-p1</Trans></p>
          <p><Trans>aboutus-p2</Trans></p>
        </header>
        <h3><Trans>aboutus-h3</Trans></h3>
        <ul>
          <li><Trans>aboutus-li-1</Trans></li>
          <li><Trans>aboutus-li-2</Trans></li>
          <li><Trans>aboutus-li-3</Trans></li>
          <li><Trans>aboutus-li-4</Trans></li>
          <li><Trans>aboutus-li-5</Trans></li>
          <li><Trans>aboutus-li-6</Trans></li>
          <li><Trans>aboutus-li-7</Trans></li>
        </ul>
        <p className='lead'><Trans>aboutus-p-lead</Trans></p>
        <h3><Trans>aboutus-h3-2</Trans></h3>
        <ul>
          <li><Trans>aboutus-li-1-1</Trans></li>
          <li><Trans>aboutus-li-1-2</Trans></li>
          <li><Trans>aboutus-li-1-3</Trans></li>
          <li><Trans>aboutus-li-1-4</Trans></li>
          <li><Trans>aboutus-li-1-5</Trans></li>
        </ul>
        <SectionTestimonials />
      </div>
    </div>

  </Layout>
);

export default AutorPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`