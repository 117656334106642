import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from 'gatsby-plugin-react-i18next';

import { Link } from "gatsby"

const sectionAboutUs = () => {
    return (
    <section className="aboutus">
        <div className="container">
            <StaticImage
                src="../images/aboutus.jpg"
                alt="React Logo"
                placeholder="blurred"
                layout="constrained"
                formats={["auto", "webp", "avif"]}
                quality={100}
            />
            <div className="content">
                <h3><Trans>history-tagline</Trans></h3>
                <h2><Trans>history-header</Trans></h2>
                <p><Trans>history-description</Trans></p>
                <Link to="/aboutus" className="btn btn-link">
                    <Trans>btn-seeAll</Trans>
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="2" viewBox="0 0 53 2">
                        <rect width="53" height="2"/>
                    </svg>
                </Link>
            </div>
        </div>
        <div className="lines">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </section>
    )
};

export default sectionAboutUs;