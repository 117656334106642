import * as React from "react"
import { Link } from "gatsby"
import { Trans } from 'gatsby-plugin-react-i18next';

const sectionServices = () => (


<section className="offer">
    <div className="container">
        <header>
            <h3><Trans>services-tagline</Trans></h3>
            <h2><Trans>services-header</Trans></h2>
        </header>
        <div className="content">
            <Link to="/business-process-simplification" id="businessProcess">
                <h4><Trans>services-bussinesProcess-header</Trans></h4>
                <p><Trans>services-bussinesProcess-description</Trans></p>
            </Link>
            <Link to="/decision-support" id="businessDecision">
                <h4><Trans>services-decisionSupport-header</Trans></h4>
                <p><Trans>services-decisionSupport-description</Trans></p>
            </Link>
            <Link to="/shared-services" id="sharedServices">
                <h4><Trans>services-sharedServices-header</Trans></h4>
                <p><Trans>services-sharedServices-description</Trans></p>
            </Link>
            <Link to="/investment-incentives" id="investmentIncentives">
                <h4><Trans>services-investmentIncentives-header</Trans></h4>
                <p><Trans>services-investmentIncentives-description</Trans></p>
            </Link>
            <Link to="/technology-deployment" id="technologyDeployment">
                <h4><Trans>services-technologyDeployment-header</Trans></h4>
                <p><Trans>services-technologyDeployment-description</Trans></p>
            </Link>
            <Link to="/process-migration" id="processMigrations">
                <h4><Trans>services-processMigrations-header</Trans></h4>
                <p><Trans>services-processMigrations-description</Trans></p>
            </Link>
            <Link to="/services" className="btn btn-link">
                <Trans>btn-seeAll</Trans>
                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="2" viewBox="0 0 53 2">
                    <rect width="53" height="2"/>
                </svg>
            </Link>

        </div>
    </div>
    <div className="lines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</section>

);

export default sectionServices;